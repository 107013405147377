<template>
  <div class="app-container calendar-list-container evaBg">
    <div class="df jcb" style="padding: 20px; width: 100%">
      <div>
        <!-- <el-button
          type="primary"
          @click="$router.back(-1)"
          style="margin-right: 40px"
          >返回</el-button
        > -->
        <!-- <template v-if="goodsInfo.length"> -->
          <!-- <el-select
            v-model="selectData.goodsName"
            placeholder="请选择商品"
            style="margin-right: 10px"
            @change="goodsChange"
          >
            <el-option
              v-for="item in goodsInfo"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <!-- <el-select
            v-model="selectData.batchNumber"
            placeholder="请选择批次"
            style="margin-right: 10px"
          >
            <template v-if="selectData.goodsIndex !== null">
              <el-option
                v-for="item in goodsInfo[selectData.goodsIndex].batchNumbers"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </template>
          </el-select> -->
          <!-- <el-button
            type="primary"
            @click="search"
            :disabled="!(selectData.goodsName && selectData.batchNumber)"
            >筛选</el-button
          >
          <el-button @click="clear">重置</el-button> -->
        <!-- </template> -->
      </div>
      <!-- <el-button
        type="primary"
        @click="
          listQuery.page = 1;
          getList();
          dialogVisible = true;
        "
        >更多信息</el-button
      > -->
    </div>
    <el-row v-if="listData.length > 0" :gutter="10">
      <el-col
        v-for="(item, index) in listData"
        :key="index"
        :span="8"
        style="margin-bottom: 10px"
      >
        <div style="padding: 30px">
          <el-card class="box-card" shadow="hover">
            <div
              slot="header"
              class="clearfix"
              style="text-align: center; height: 40px"
            >
              <span style="color: #fff">{{ item.name }}</span>
            </div>
            <div>
              <ve-histogram
                :data="item.chartData"
                :settings="chartSettings"
                :extend="chartExtend"
                :tooltip="tooltip"
              ></ve-histogram>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <div v-else style="text-align: center">无数据</div>

    <!-- <el-dialog title="详情" :visible.sync="dialogVisible" width="60%">
      <el-table
        ref="table"
        v-loading="listLoading"
        :data="list"
        :height="tableHeight"
        element-loading-text="加载中..."
        border
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column type="index" align="center" label="序号" width="100" />
        <el-table-column min-width="100" align="center" label="姓名">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100" align="center" label="手机">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100" align="center" label="意见">
          <template slot-scope="scope">
            <span style="color: #e6a23c">{{ scope.row.tasteType }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100" align="center" label="其他建议">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>
        <el-table-column width="80" align="center" label="图片">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.imgList.length > 0"
              icon="el-icon-picture"
              type="primary"
              size="mini"
              circle
              @click="showImg(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          :current-page="listQuery.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="listQuery.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-dialog>
    <el-dialog
      title="查看图片"
      :visible.sync="imgVisible"
      :width="imgDialogWidth"
    >
      <el-image
        v-for="(img, imgIndex) in imgList"
        :key="imgIndex"
        style="width: 300px; height: 300px"
        :src="img"
        fit="cover"
        :preview-src-list="imgList"
        class="mr20 t-border mb10"
      ></el-image>
    </el-dialog> -->
  </div>
</template>

<script>
import api from "../../../util/extra-api";
import { local } from "../../../util/util";
export default {
  data() {
    this.chartSettings = {
      roseType: "radius",
    };
    this.chartExtend = {
      // x轴的文字倾斜
      "xAxis.0.axisLabel.rotate": 45,
      "xAxis.0.axisLabel.textStyle.color": "#fff",
      "yAxis.0.axisLabel.textStyle.color": "#fff",
      yAxis: {
        //是否显示y轴线条
        axisLine: {
          show: true,
        },
        // 纵坐标网格线设置，同理横坐标
        splitLine: {
          show: false,
        },
        // 线条位置
        position: "left",
      },
      xAxis: {
        axisLine: {
          show: true,
        },
      },
      series: {
        barMaxWidth: 35,
        label: {
          show: true,
        },
      },
    };
    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
        },
        formatter: (name) => {
          // console.log(name);
          return "出现次数:" + name[0].data;
        },
      },
      imgList: "",
      templateId: null,
      imgVisible: false,
      listLoading: false,
      imgDialogWidth: null,
      listQuery: {
        companyId: null,
        id: null,
      },
      dialogVisible: false,
      listData: [],
      list: [],
      query: {
        qr_count: "",
        start_time: "",
        end_time: "",
      },
      total: 0,
      timeRange: "",
      goodsInfo: null,
      selectData: {
        goodsId: null,
        goodsIndex: null,
        goodsName: null,
        batchNumber: "",
      },
      tableHeight: null,
    };
  },
  mounted() {
    // console.log(this.$route.params.batchNumber,1);
    // console.log(this.$route.params.goodsId,2);
    // console.log(this.$route.params.templateId,3);
    // console.log(this.$route);
    this.listQuery.companyId = local.get("company_id");
    this.listQuery.id = this.$route.query.id;
    // this.id = this.$route.query.id;
    this.getData(this.$route.query.templateId);
    // this.selectBind();
  },
  methods: {
    handleSizeChange(size) {
      this.listQuery.limit = size;
      this.getList();
    },
    clear() {
      //   this.selectData.goodsId = null;
      //   this.selectData.goodsIndex = null;
      //   this.selectData.goodsName = null;
      //   this.selectData.batchNumber = "";
      //   this.getData({
      //     templateId: this.$route.query.templateId,
      //   });
    },
    goodsChange(val) {
      // console.log(val);
      this.selectData.goodsId = this.goodsInfo[val].goodsId;
      this.selectData.goodsName = this.goodsInfo[val].goodsName;
      this.selectData.goodsIndex = val;
    },
    handleCurrentChange(page) {
      this.listQuery.page = page;
      this.getData({
        templateId: this.templateId,
      });
    },
    selectBind() {
      api
        .get("/v1/pc/evaluation/goods/selectBind", {
          templateId: this.templateId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.goodsInfo = res.data.map((v, i) => {
              v.label = v.goodsName;
              v.value = i;
              v.batchNumbers = v.batchNumbers.map((item, index) => {
                let data = {
                  batchNumber: item,
                };
                data.label = item;
                data.value = item;
                return data;
              });
              return v;
            });
            // console.log(this.goodsInfo, 123);
          }
        });
    },
    showImg(row) {
      if (row.imgList.length == 1) {
        this.imgDialogWidth = "25%";
      } else if (row.imgList.length == 2) {
        this.imgDialogWidth = "30%";
      } else {
        this.imgDialogWidth = "40%";
      }
      this.imgList = row.imgList;
      this.imgVisible = true;
    },
    getData() {
      api.get("/v1/wx/voting/chartData", this.listQuery).then((res) => {
        if (res.code === 200) {
          res.data.forEach((r) => {
            r.chartData = {};
            r.chartData.columns = ["key", "value"];
            r.chartData.rows = r.data;
            delete r.data;
          });
          console.log(res.data, 111);
          this.listData = res.data;
        }
      });
    },
    getList() {
      this.listLoading = true;
      api
        .get("/v1/pc/evaluation/evaluateList", this.listQuery)
        .then((response) => {
          this.tableHeight =
            window.innerHeight - this.$refs.table.$el.offsetTop - 85;
          const results = response.data;
          this.list = results;
          this.total = response.total;
          this.listLoading = false;
        });
    },
    reset() {
      this.query = {
        qr_count: "",
        start_time: "",
        end_time: "",
      };
      this.timeRange = "";
      this.getData({
        batchNumber: this.$route.params.batchNumber,
        goodsId: this.$route.params.goodsId,
        templateId: this.$route.params.templateId,
      });
    },
    submit() {
      if (this.timeRange) {
        this.query.start_time = this.timeRange[0];
        this.query.end_time = this.timeRange[1];
      }
      this.getData({
        batchNumber: this.$route.params.batchNumber,
        goodsId: this.$route.params.goodsId,
        templateId: this.$route.params.templateId,
      });
    },
  },
};
</script>
<style>
.el-card {
  background-color: transparent;
}
.evaBg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
}
.jcb {
  justify-content: space-between;
}
</style>
